<template>
  <b-modal
    centered
    :id="idModal"
    modal-class="edit-modal"
    title-class="edit-modal-title"
    header-class="edit-modal-header"
    footer-class="edit-modal-footer"
    cancel-variant="outline-primary"
    :title="titleModal"
    :ok-title-html="okTitleModal"
    :ok-disabled="isSubmited"
    :cancel-title="$t('cancel-button')"
    @ok="onSubmit"
    @hidden="resetModal('hidden')"
    @show="resetModal('show')"
  >
    <slot />
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";

export default {
  name: "EditModal",
  components: { BModal },
  props: {
    type: {
      type: String,
      required: true,
    },
    title_name: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: function () {
        return this.$t("edit-modal-title").replace(":type");
      },
    },
    okTitle: {
      type: String,
      required: false,
      default: function () {
        return this.$t("save-button");
      },
    },
    id: {
      type: String | Number,
      required: false,
    },
    okStatus: {
      type: Boolean,
      required: false,
      default: false,
    },
    cancelStatus: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      titleModal: this.title,
      okTitleModal: this.okTitle,
      okStatusModal: this.okStatus,
      cancelStatusModal: this.cancelStatus,
      isSubmited: false,
      idModal: this.$helpers.isset(this.id) ? `edit-modal-${this.type}-${this.id}` : `edit-modal-${this.type}`,
    };
  },
  methods: {
    onSubmit(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.okTitleModal = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>`;
      this.isSubmited = true;
      this.$emit("submit");
    },
    resetModal($type) {
      this.$emit($type);
      this.isSubmited = false;
      this.okStatusModal = false;
      this.okTitleModal = this.okTitle;
    },
  },
  watch: {
    okStatus: function (value) {
      this.okStatusModal = value;
      if (value) this.$nextTick(() => this.$bvModal.hide(this.idModal));
    },
    cancelStatus: function (value) {
      this.cancelStatusModal = value;
      if (value) this.$nextTick(() => this.$bvModal.hide(this.idModal));
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />
