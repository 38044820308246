<template>
  <validation-observer ref="profileValidation" class="profile">
    <b-form @submit.prevent="validationForm">
      <div
        class="profile-cover"
        :style="{ backgroundImage: `url(${coverImage})` }"
      >
      <div class="overlay">
          <label
            for="profile_cover"
            class="position-absolute p-0 cursor-pointer"
          >
            <FeatherIcon icon="CameraIcon" class="text-primary" size="17" />
          </label>
          <RecommendationUploadImage
            :overlay="true"
            :dimensions="{ width: 1200, height: 750 }"
          />
        </div>
        <b-form-file
          id="profile_cover"
          v-model="form_data.cover"
          accept="image/*"
          class="d-none"
        />

        <b-avatar
          badge
          badge-variant="light"
          class="profile-avatar"
          size="9rem"
          :alt="userData.full_name"
          :src="avatarImage"
        >
          <template #badge>
            <label for="avatar" class="cursor-pointer">
              <FeatherIcon icon="CameraIcon" class="text-primary" size="17" />
            </label>
            <b-form-file
              id="avatar"
              v-model="form_data.avatar"
              accept="image/*"
              class="d-none"
            />
          </template>
        </b-avatar>
        <RecommendationUploadImage
          :dimensions="{ width: 110, height: 110 }"
          class="custom-recommendation-image"
        />
      </div>
      <b-container class="profile-details">
        <b-row>
          <b-col lg="4" md="6" sm="12">
            <b-form-group
              label-cols="12"
              label-for="first_name"
              :label="$t('profile/first_name')"
            >
              <validation-provider
                #default="{ errors }"
                name="first name"
                vid="first_name"
                rules="required|max:255"
              >
                <b-form-input
                  id="first_name"
                  name="first_name"
                  v-model="form_data.first_name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12">
            <b-form-group
              label-cols="12"
              label-for="middle_name"
              :label="$t('profile/middle_name')"
            >
              <b-form-input
                id="middle_name"
                name="middle_name"
                v-model="form_data.middle_name"
              />
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12">
            <b-form-group
              label-cols="12"
              label-for="last_name"
              :label="$t('profile/last_name')"
            >
              <validation-provider
                #default="{ errors }"
                name="last name"
                vid="last_name"
                rules="required|max:255"
              >
                <b-form-input
                  id="last_name"
                  name="last_name"
                  v-model="form_data.last_name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-cols="12"
              label-for="email"
              :label="$t('profile/email')"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  type="email"
                  name="email"
                  v-model="form_data.email"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-cols="12"
              label-for="password"
              :label="$t('profile/password')"
            >
              <b-input-group>
                <b-form-input
                  id="password"
                  type="password"
                  readonly
                  placeholder="********"
                  class="profile-password"
                />
                <b-input-group-append>
                  <b-button
                    class="edit-password"
                    variant="light"
                    @click="$bvModal.show('edit-modal-password')"
                  >
                    <FeatherIcon icon="EditIcon" />
                  </b-button>
                  <EditPassword />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              :label="$t('profile/phone')"
              class="control-input country-code-input custom-label"
            >
              <b-input-group class="box-shadow-0 w-100">
                <template #prepend>
                  <vue-country-code
                    ref="vcc"
                    v-model="form_data.phone.country_code"
                    @onSelect="onSelect"
                    :disabled="true"
                    default-country="sa"
                    :only-countries="['sa']"
                    :enabledCountryCode="false"
                  >
                  </vue-country-code>
                </template>
                <validation-provider
                  class="profile-phone"
                  #default="{ errors }"
                  name="phone"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.phone.phone"
                    :state="errors.length > 0 ? false : null"
                    name="phone"
                    type="number"
                    :placeholder="$t('g.phone')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="text-center my-2">
          <b-col cols="12">
            <b-button
              type="submit"
              variant="primary"
              class="submit-button w-75"
              @click="validationForm"
              :disabled="isSubmited"
              >{{ $t("g.save") }}</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </b-form>
    <OverlayComponent :isLoading="overlayLoading" />
  </validation-observer>
</template>

<script>
import {
  BImg,
  BCol,
  BRow,
  BAvatar,
  BButton,
  BFormGroup,
  BFormInput,
  BContainer,
  BInputGroup,
  BInputGroupAppend,
  BForm,
  BFormFile,
} from "bootstrap-vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import VueCountryCode from "vue-country-code";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required, email, max } from "@core/utils/validations/validations";
import EditModal from "@/components/shared/EditModal/index.vue";
import EditPassword from "@/components/Profile/editPassword/index.vue";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";


export default {
  name: "Profile",
  components: {
    EditPassword,
    BForm,
    BImg,
    BCol,
    BRow,
    BAvatar,
    BButton,
    BFormFile,
    BFormGroup,
    BFormInput,
    BContainer,
    BInputGroup,
    BInputGroupAppend,
    EditModal,
    VueCountryCode,
    ValidationProvider,
    ValidationObserver,
    OverlayComponent,
    RecommendationUploadImage
  },
  data() {
    return {
      max,
      email,
      required,
      overlayLoading: false,
      userData: JSON.parse(localStorage.getItem("userData")),
      isSubmited: false,
      coverImage: null,
      avatarImage: null,
      form_data: {
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        avatar: null,
        cover: null,
        phone: {
          phone: null,
          country_code: null,
        },
      },
    };
  },
  created() {
    this.coverImage = this.$helpers.filterImages(
      "cover",
      this.filterImageByName(this.userData.media, "cover"),
      this.userData.full_name,
      require("@/assets/images/pages/vendor-cover.jpeg")
    );
    this.avatarImage = this.$helpers.filterImages(
      "avatar",
      this.filterImageByName(this.userData.media, "avatar"),
      this.userData.full_name
    );
    Object.keys(this.form_data).forEach((key) => {
      if (key === "phone") {
        this.form_data[key].phone = this.userData[key].phone || null;
        this.form_data[key].country_code =
          this.userData[key].country_code || null;
      } else if (key === "avatar" || key === "cover") {
        this.form_data[key] = null;
      } else {
        this.form_data[key] = this.userData[key] || null;
      }
    });
  },
  computed: {
    okHtml() {
      return this.isSubmited
        ? '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
        : this.$t("g.save");
    },
  },
  methods: {
    onSelect(country) {
      this.form_data.phone.country_code = country.dialCode;
    },
    filterImageByName(images, name) {
      return this.$helpers.isset(images) && this.$helpers.isArray(images)
        ? images.filter((image) => image.name === name)
        : null;
    },
    validationForm() {
      this.$refs.profileValidation
        .validate()
        .then((success) => {
          this.overlayLoading = true;
          if (success) {
            this.isSubmited = true;
            let formData = new FormData();

            Object.keys(this.form_data).forEach((key) => {
              if (
                this.form_data[key] &&
                !this.$helpers.isNull(this.form_data[key])
              ) {
                if (key === "phone") {
                  formData.append(key, this.form_data.phone.phone);
                  formData.append(
                    "country_code",
                    this.form_data.phone.country_code
                  );
                } else {
                  formData.append(key, this.form_data[key]);
                }
              }
            });

            this.$http
              .post(`/auth/edit-profile?_method=PUT`, formData)
              .then((res) => {
                if (res.status === 200) {
                  if (this.form_data.avatar) {
                    this.$store.dispatch(
                      "avatar_change",
                      this.form_data.avatar
                    );
                  }
                  this.$helpers.makeToast(
                    "success",
                    res.data.message,
                    res.data.message
                  );
                  this.getUpdatedData();
                  this.overlayLoading = false;
                }
              })
              .catch((err) => {
                this.overlayLoading = false;
                this.$helpers.handleError(err);
              })
              .finally(() => {
                this.overlayLoading = false;

                this.isSubmited = false;
              });
          } else {
            this.overlayLoading = false;
          }
        })
        .catch((err) => {
          this.overlayLoading = false;

          this.$helpers.handleError(err);
        });
    },
    getUpdatedData() {
      this.overlayLoading = true;
      this.$http
        .get(`/auth/profile`)
        .then((res) => {
          this.userData = res.data.data;
          localStorage.setItem("userData", JSON.stringify(res.data.data));
          this.overlayLoading = false;
        })
        .catch((err) => {
          this.overlayLoading = false;
          this.$helpers.handleError(err);
        });
    },
  },
  watch: {
    "form_data.avatar": function (val) {
      if (this.$helpers.isset(val) && this.$helpers.isObject(val)) {
        this.avatarImage = URL.createObjectURL(val);
      }
    },
    "form_data.cover": function (val) {
      if (this.$helpers.isset(val) && this.$helpers.isObject(val)) {
        this.coverImage = URL.createObjectURL(val);
      }
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
